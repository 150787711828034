<template>
     <iframe src="editor_emr.html" v-bind="objectOfAttrs" ref="iframe"></iframe>
</template>

<script>
export default {
     data() {
          return {
               objectOfAttrs: {
                    width: '100%',
                    height: '100%',
                    frameborder: 0
               }
          }
     },
     mounted() {
          const iframe = this.$refs.iframe;
          iframe.onload = function () {
               setTimeout(() => {
                    let iframeDocument = iframe.contentDocument;
                    if (iframeDocument) {
                         const doms = iframeDocument.getElementsByClassName('menu-container');
                         for (let i = 0; i < doms.length; i++) {
                              doms[i].style.maxHeight = '500px';
                              doms[i].style.overflowY = 'auto';
                         }
                         var element = iframeDocument.getElementById('_right-panel-head');
                         if (element) element.lastElementChild.innerHTML = '控件库';
                         // 隐藏页眉、页脚
                         var emreditor = iframeDocument.getElementById('_emreditor');
                         var emreditorDocument = emreditor.contentDocument;
                         if (emreditorDocument) {
                              var pagelines = emreditorDocument.getElementsByClassName('pageline');
                              for (let i = 0; i < pagelines.length; i++) {
                                   if (pagelines[i]) {
                                        pagelines[i].style.display = 'none'
                                   }
                              }
                         }

                    }
               }, 1000);
          }
     }
}
</script>
