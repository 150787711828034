<template>
    <div>
        <el-dialog title="选择音频" :visible.sync="audioBox" width="30%" top="10vh" @close="closeAudioDialog">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="文件上传">
                    <el-upload class="upload-demo" :accept="'audio/*'" :before-upload="beforeUpload"
                        action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km" :show-file-list="true"
                        :limit="1" :on-exceed="handleExceed" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                        :file-list="fileList" ref="uploadRef" :headers="headers">
                        <el-button size="small" type="primary" icon="el-icon-upload">点击上传
                        </el-button>
                    </el-upload>
                </el-form-item>
                <el-form-item label="自动播放">
                    <el-checkbox v-model="form.autoplay"></el-checkbox>
                </el-form-item>
                <!-- <el-form-item label="播放控件">
                    <el-checkbox v-model="form.controls"></el-checkbox>
                </el-form-item> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeAudioDialog">{{
            $t("cip.cmn.btn.celBtn")
        }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit">
                    确定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from "@/util/auth";
export default {
    data() {
        return {
            audioBox: true,
            fileList: [],
            form: {
                autoplay: true,
                controls: true
            }
        };
    },

    components: {},

    computed: {
        headers: function () {
            return { "Sinoma-Auth": getToken() };
        },
    },

    mounted() { },

    methods: {
        beforeUpload(file) {
            const isVideo = file.type.startsWith('audio/');
            if (!isVideo) {
                this.$message.error('只能上传音频文件');
            }
            return isVideo;
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
        },
        handleAvatarSuccess(res, file, fileList) {
            if (res.code == 200) {
                this.fileList.push({
                    name: res.data.originalName,
                    url: res.data.link,
                });
                this.$message({
                    type: "success",
                    message: "上传成功！",
                });
            }
        },
        handleRemove(file, fileList) {
            this.fileList = []
        },
        closeAudioDialog() {
            this.$emit('selectAudio', '')
            this.audioBox = false
        },
        submit() {
            if(this.fileList.length == 0){
                this.$message.warning('请先上传音频文件');
                return;
            }
            let videoObj = {
                ...this.form,
                videoUrl: this.fileList[0].url
            }
            this.$emit('selectAudio', videoObj)
            this.audioBox = false
        }
    },
}
</script>

<style scoped lang='scss'>
::v-deep .el-dialog__footer {
    text-align: center;
}
::v-deep .el-input{
    width: 100px;
    margin-right: 10px;
}
</style>