<template>
    <div>
        <el-dialog title="选择视频" :visible.sync="videoBox" width="30%" top="10vh" @close="closeVideoDialog">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="文件上传">
                    <!-- <el-upload class="upload-demo" :accept="'video/*'" :before-upload="beforeUpload"
                        action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km" :show-file-list="true"
                        :limit="1" :on-exceed="handleExceed" :on-success="handleAvatarSuccess" :on-remove="handleRemove"
                        :file-list="fileList" ref="uploadRef" :headers="headers">
                        <el-button size="small" type="primary" icon="el-icon-upload">点击上传
                        </el-button>
                    </el-upload> -->
                    <SingleFIleUpload ref='SingleFIleUpload' :showView="false" @fileIds="handleAvatarSuccess" accept="" fileSize="2000" @handleRemove = "handleRemove" ></SingleFIleUpload>
                </el-form-item>
                <el-form-item label="宽度">
                    <el-input-number v-model="form.width" :min="0"></el-input-number>像素
                    <!-- <el-input v-model="form.width"></el-input> -->
                </el-form-item>
                <el-form-item label="高度">
                    <el-input-number v-model="form.height" :min="0"></el-input-number>像素
                    <!-- <el-input v-model="form.height"></el-input>像素 -->
                </el-form-item>
                <el-form-item label="自动播放">
                    <el-checkbox v-model="form.autoplay"></el-checkbox>
                </el-form-item>
                <el-form-item label="播放控件">
                    <el-checkbox v-model="form.controls"></el-checkbox>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeVideoDialog">{{
            $t("cip.cmn.btn.celBtn")
        }}</el-button>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="submit">
                    确定
                </el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getToken } from "@/util/auth";
import SingleFIleUpload from "@/components/file-upload/SingleFIleUpload";
export default {
    data() {
        return {
            videoBox: true,
            fileList: [],
            form: {
                width: 400,
                height: 300,
                autoplay: true,
                controls: true
            }
        };
    },

    components: {SingleFIleUpload},

    computed: {
        headers: function () {
            return { "Sinoma-Auth": getToken() };
        },
    },

    mounted() { },

    methods: {
        beforeUpload(file) {
            const isVideo = file.type.startsWith('video/');
            if (!isVideo) {
                this.$message.error('只能上传视频文件');
            }
            return isVideo;
        },
        handleExceed(files, fileList) {
            this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
        },
        handleAvatarSuccess(file) {
            if (file.status == 'success') {
                this.fileList.push({
                    name: file.name,
                    url: file.response.path,
                });
                this.$message({
                    type: "success",
                    message: "上传成功！",
                });
            }
        },
        handleRemove(file, fileList) {
            this.fileList = []
        },
        closeVideoDialog() {
            this.$emit('selectVideo', '')
            this.videoBox = false
        },
        submit() {
            if(this.fileList.length == 0){
                this.$message.warning('请先上传视频文件');
                return;
            }
            let videoObj = {
                ...this.form,
                videoUrl: this.fileList[0].url
            }
            this.$emit('selectVideo', videoObj)
            this.videoBox = false
        }
    },
}
</script>

<style scoped lang='scss'>
::v-deep .el-dialog__footer {
    text-align: center;
}
::v-deep .el-input-number{
    margin-right: 10px;
}
</style>